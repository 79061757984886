@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  background-color: #fff !important;
  color: #000 !important;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  -webkit-text-fill-color: #000 !important;
  border-bottom: 2px solid #dedede;
}

input[type="password"]::-webkit-contacts-auto-fill-button,
input[type="password"]::-webkit-credentials-auto-fill-button,
input[type="password"]::-webkit-plaintext-toggle {
  display: none !important;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none !important;
}

input[type="password"]::-moz-show-senitive,
input[type="password"]::-moz-focus-inner {
  border-style: none !important;
  outline: none !important;
}

.dropdown {
  top: 50%;
  transform: translateY(-50%);
}

button[disabled] {
  background-color: #989898;
  color: #f4f4f4 !important;
}

button[disabled]:hover {
  background-color: #989898;
  color: #f4f4f4 !important;
  cursor: not-allowed;
}

/*.tooltip {
  position: relative;
}

.tooltip::after {
  content: attr(title);
  position: absolute;
  top: -2.5rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem;
  background-color: #333;
  color: #fff;
  font-size: 0.875rem;
  border-radius: 0.50rem;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
}

.tooltip:hover::after {
  opacity: 1;
}
*/
.upload-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="date"] {
  width: 170px;
  border: none !important;
}

.commodity .date .input,
.service .date .input,
.time-chartering .service .date .input {
  border-bottom: 2px solid #dedede;
}

/*input[type="date"]::after {
    border-bottom: 2px solid #dedede;
    width: 103%;
    content: "";
    position: absolute;
    bottom: 0;
}*/

input[type="date"]:focus::after {
  border-color: #3bb34b;
}

input::placeholder,
textarea::placeholder {
  font-style: italic !important;
  color: #94a3b8 !important;
}

.carbon input[type="date"]::after {
  display: none;
}

.modal-open {
  cursor: not-allowed; /* Curseur "not-allowed" */
}

.disabled input {
  pointer-events: none;
  background-color: #f2f2f2;
}

.modall {
  position: fixed;
  top: 12%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  width: 80%;
  height: 80vh; /* Ajustez la hauteur du modal selon vos besoins */
  padding: 2rem;
  border-radius: 0.5rem;
  overflow: scroll;
  z-index: 99999; /* Augmentez la valeur du z-index */
}

.popup-modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  width: 50%;
  height: 150px; /* Ajustez la hauteur du modal selon vos besoins */
  padding: 2rem;
  border-radius: 0.5rem;
  z-index: 99999; /* Augmentez la valeur du z-index */
}

.popup-edit-modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 10%;
  left: 50%;
  bottom: 10%;
  transform: translateX(-50%);
  background-color: white;
  width: 80%;
  height: auto; /* Ajustez la hauteur du modal selon vos besoins */
  padding: 1rem 0;
  border-radius: 0.5rem;
  overflow: auto;
  z-index: 99999; /* Augmentez la valeur du z-index */
}

.popup-partner-modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 10%;
  left: 50%;
  bottom: 10%;
  transform: translateX(-50%);
  background-color: white;
  width: 70%;
  height: 550px; /* Ajustez la hauteur du modal selon vos besoins */
  padding: 2rem;
  border-radius: 0.5rem;
  overflow: auto;
  z-index: 99999; /* Augmentez la valeur du z-index */
}

.modall-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Couleur de superposition semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
}

.modal-close-button {
  /* Ajoutez des styles de bouton de fermeture selon vos préférences */
}

.close-button-right {
  position: absolute;
  top: 1rem; /* Ajustez la valeur pour déplacer le bouton vers le bas */
  right: 1rem; /* Ajustez la valeur pour déplacer le bouton vers la gauche */
}

.table {
  display: table;
  border-collapse: collapse;
  width: 100%;
}

.thead {
  display: table-header-group;
}

.tr {
  display: table-row;
}

.th,
.td {
  display: table-cell;
  border: 1px solid #ccc;
  padding: 8px;
}

.resizer {
  display: inline-block;
  width: 10px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  background: #eee;
  cursor: col-resize;
}

a {
  word-break: break-all;
}

.retirement-row input,
select,
option {
  height: 40px !important;
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .checkbox-grid .checkItems {
    grid-template-columns: 1fr;
  }
}

.nospace .choice {
  margin-bottom: 0 !important;
}

.InovuaReactDataGrid {
  border: none;
  border-radius: 12px 12px 0 0;
}

.InovuaReactDataGrid__header {
  background: #3d4196 !important;
  color: #fff;
  border-radius: 2rem 12px 0 0;
}

.InovuaReactDataGrid,
.InovuaReactDataGrid__column-header {
  text-align: center !important;
  font-weight: 400;
}

.InovuaReactDataGrid__column-header__resize-wrapper {
  border: none !important;
}

.InovuaReactDataGrid__column-header__content.InovuaReactDataGrid__box--ellipsis {
  font-weight: 400 !important;
}

.InovuaReactDataGrid {
  border: none !important;
}

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__header,
.InovuaReactDataGrid--theme-default-light
  .InovuaReactDataGrid__header-wrapper__fill {
  border: none !important;
}

.text-coc-blue ~ span.text-black {
  word-break: break-word !important;
 /* max-width: 50%;*/
}

/*.gg {
  max-width: 10rem;
  text-wrap: wrap !important;
  padding: 0.5rem;
  text-align: center;
}*/

.addlineBtn {
  font-size: 14px;
}

.addlineBtn:focus {
  outline: 0;
}

.sendBtn {
  margin-left: 1rem;
  font-size: 14px !important;
  background-color: #3d4196;
  padding: 0.5rem 1rem 0.5rem 1rem;
  color: white;
  border-radius: .3rem!important;
}

.button-wrapper {
  padding-right: 1.5rem;
  display: flex;
  justify-content: flex-end;
}

.tooltip-content {
  word-break: break-word;
}

.recap-transaction .flex.py-2 {
    flex-direction: column;
    /* max-width: 80%; */
    /* border-right: 1px solid #ddd; */
}

span.text-coc-blue {
    font-weight: 500;
    /* font-size: 13px; */
    letter-spacing: 0.5px;
    /* text-transform: uppercase; */
}

.flex.py-2.logoutButton {
  flex-direction: row;
}
