:root{
	--blue-primary: #3D4196;
	--green-primary: #3BB34B;
}

body{

	width: 100%;
	height: 100%;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-size: 16px;
}

body *{

	font-family: Poppins, sans-serif;
	margin: 0;
	padding: 0;
}

header{
    background: #3D4196 url(/public/assets/images/banner.jpg) no-repeat right -6vw top;
    background-size: cover;
    display: flex;
    align-items: center;
    max-width: 100vw;
    height: 71px;
    padding: 0 2rem;
    align-content: center;
    justify-content: space-between;
}

.logo img{
	width: 150px;
	height: auto;
	background-size: contain;
}

.menu {
	display: flex;
	margin: 0 auto;
    align-items: center;
}

.menu li {
	list-style: none
}

.menu li a {
	color: #DEDEDE;
    position:relative;
	text-decoration: none;
	margin-right: 2rem;
	font-size: 13px;
    font-weight: 500;
    transition: all 0.6s ease-out;
}

.menu li a::after  {
   content:"";
   bottom: 0;
    width: 0;
    height:2px;
    background: transparent;
    position: absolute;
    left: 0;
    bottom: -5px;
    transition: width 0.3s ease-in-out;
}

.user-connected {
    width: 35px;
    height: 35px;
    background: #fff;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 600;
}

.menu li a:hover::after {
    content:"";
    bottom: 0;
    width: 100%;
    height:2px;
    background: #329940;
    position: absolute;
    left: 0;
    bottom: -5px
}

.menu li a:hover {
    color: #fff;
    font-size: 13px;
    font-weight: 500;
}

.menu li .active::after {
    content: "";
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #329940;
    position: absolute;
    left: 0;
    bottom: -5px;
}

.menu li .active{

    color: #fff;
    font-weight: 500;
    position: relative
}
