.carbon input {

    width: 100%
}

/*.carbon th, .carbon td {

    width: 100px;
    
}*/

.carbon th:last-of-type{

    width: 100px;
    
}

.carbon select {
  white-space: nowrap;
  overflow: hidden;
  padding: 0 20px 0px 10px;
  text-overflow: ellipsis;
}

th.resizable {
  resize: horizontal;
  overflow: hidden;
}

th.resizable::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 20px; /* Width of the resizing handle */
  cursor: col-resize;
  background-color: transparent;
}

.disabled-svg {
  fill: gray; /* Changer la couleur du SVG lorsque désactivé */
}