.partners td{
	color: #989898;
	max-width: 200px;
    text-wrap: wrap;
}

.partners tbody:before{
    display: none !important
} 

