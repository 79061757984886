.admin-users th{

	font-weight: 400;

}
.admin-users a:hover{

	text-decoration: none;
}

.admin-users ::placeholder{

	color: #989898 ;
}


.admin-users.popup-modal{


	height: auto;
}