.dashboard td, .dashboard td{

	height: 54px;
/*	margin-left: 1rem;
*/
}

.table-wrapper .estimatedDate svg{

	margin: 0!important;

}

tbody:before {
  content: '';
  display: block;
  height: 10px;
}

.selected{

	background: #DEDEDE
}

#coc-table th:not(:nth-child(-n+2)):not(:first-child), #usertable th:not(:first-child):not(:last-child):not(:nth-last-child(2)){

	  min-width: 200px;

}

/* Table wrapper client*/

@media screen and (min-width: 1280px){

	.table-wrapper{

		max-height: 65vh;
	}
}

@media screen and (min-width: 1560px){

	.table-wrapper{

		max-height: 75vh;
	}
}

/* Table wrapper coc*/

@media screen and (min-width: 1280px){

	.table-wrapper-coc{

		max-height: 50vh;
	}
}

@media screen and (min-width: 1560px){

	.table-wrapper-coc{

		max-height: 65vh;
	}
}

.dashboard thead::before{
    content:" ";
    position: absolute;
    top:-2px;
    width: 100%;
    height: 10px;
    background: #3D4196;
    border-top-left-radius: 0.5rem;
    display: block
}

@media screen and (min-width:1024px) {
	
	.estimatedDate{
		max-width: 600px;
		width: 200px;
	}
}


