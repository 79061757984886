/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.form-steps{

    height: 500px;
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 20px;
}

.service .choice{

    margin-bottom: 0!important;
}

p{


    font-size: 0.9rem;
    word-spacing: 2.5px;
    line-height: 1.7!important;
}


.form-steps .item{
    background: grey;
    color: #fff;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
}

.content{

    min-width: 589px;
    border-radius: 2px;
    top: -100px;
    left: -250px;
    visibility: hidden;
}

.product .content, .service .content{

     top: -130px;

}

.popup:hover + .content{

    visibility: visible;
    z-index: 1000;
}


.bundaries .popup + .content {
 min-width:400px;
    top: 70px;
    width: 50%; 
    top: -220%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
     text-align: center
 }



.commodity .choice, .flexible .choice, .product .choice, .flexible .choice{

    margin-bottom: 0!important;
}

.form-steps .item.active{

background: #3D4196

}

.complete.item::after {
    -webkit-transition: width 1s ease-in;
    -o-transition: width 1s ease-in;
    transition: width 1s ease-in;
    content: " ";
    background-image: url("/public/assets/icons/checked.svg");
    position: absolute;
    width: 40px;
    z-index: 40;
    height: 40px;
    background-repeat: no-repeat;
    background-size: contain;
}

.assurance .choice{

    margin-bottom: 0!important;
}

.commodity input[type="text"], .product input[type="text"]{

    max-width: 70% !important;
}

.commodity-container .choice{

    margin-bottom: 0!important;
}