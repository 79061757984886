table.draggable{
    border-collapse: collapse;
    margin-left: 50px;
}
table.draggable th{
    border: 1px solid gray;
    position: relative;
    padding: 0 20px;
}
.draggable .Dragger{
    cursor: col-resize;
    height: 100%;
    position: absolute;
    right: -4px;
    top: 0;
    width: 8px;
    z-index: 1;
}