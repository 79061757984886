.step-item{

    @apply relative flex flex-col justify-center items-center w-screen text-center;
}

.step-item:not(:first-child)::before{

    @apply content-[''] bg-light-grey absolute h-[6px] w-full min-w-full right-2/4 top-1/3 -translate-y-2;

}

.step{

    @apply w-10 h-10 z-10 flex justify-center items-center text-white relative bg-grey rounded-full font-semibold 
}


/* Ajout des icons checked */
.active .step::after, .complete .step::after {
    transition: all 0.3s ease-in;
    opacity: 1;
    content: " ";
    background-image: url("/public/assets/icons/checked.svg");
    position: absolute;
    width: 42px;
    z-index: 40;
    height: 42px;
    background-repeat: no-repeat;
    background-size: contain;
}


/* Ajout des icons warn si statut pending */
.pending .step::after {

    content: " ";
    background-image: url("/public/assets/icons/warn.svg");
    z-index: 40;
    position: absolute;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: contain;

}

/* Couleur de la ligne horizontale*/
.complete:not(:first-child):before, .active:not(:first-child):before, .pending:not(:first-child)::before{

    @apply  bg-coc-green z-0;

}


.line p{

    font-weight: 500;
}


.line p::after{

    @apply content-[''] w-[4px] bg-light-grey -z-10 left-2/4 absolute h-16 -translate-y-20  top-0;
}

.step-item:first-child p{

    max-width: 100px;
}

/*.line1{

    left: 12vw
}

.line2{

    left: 20vw
}

.line3{

    left: 30vw
}
*/
/*Responsive stepper */


/* 1024px */
@media screen and (max-width:1024px){


 .subtitle.first{

    left:0
}

.line1{

    left: 8vw
}

.line3{

    left: 16vw
}

.line2{

    left: 15%
}

}


/*1280px*/
@media screen and (min-width:1026px) and (max-width:1280px){

.line1{

    left: 10vw
}

.line2{

    left: 16vw
}

    .line3{

    left: 23vw
}
}

/*1360px à 1366px*/

@media screen and (min-width:1282px) and (max-width:1380px){

.line1{

    left: 12vw
}

.line2{

    left: 18vw
}

    .line3{

    left: 25%
}
}


/*1400 à 1440*/

@media screen and (min-width:1400px) and (max-width:1480px){

.line1{

    left: 12vw
}

.line2{

    left: 18vw
}

    .line3{

    left: 25vw
}
}

/*1500px ~ 1540px*/

@media screen and (min-width:1490px) and (max-width:1530px){

     .subtitle.first{

    left:2rem
}

    .line1{

    left: 8vw
}

.line2{

    left: 16vw
}

    .line3{

    left: 23vw
}
}


@media screen and (min-width:1530px) and (max-width:1540px){

     .subtitle.first{

    left:5rem
}

    .line1{

    left: 12vw
}

.line2{

    left: 20vw
}

    .line3{

    left: 28vw
}
}

/*1600 à 1680*/

@media screen and (min-width:1600px) and (max-width:1680px){

.line1{

    left: 12vw
}

.line2{

    left: 21vw
}

    .line3{

    left: 28vw
}
}

/*1700 à 1980px*/

@media screen and (min-width:1700px) and (max-width:1980px){

        .subtitle.first{

    left:0!important
}


.line1{

    left: 7vw
}

.line2{

    left: 17vw
}

    .line3{

    left: 27vw
}
}






.subtitle{

    margin-right:1.5rem
}





.step-item.selected-element .step::after, .step-item.selected-element:hover .step::after{

   transform: scale(1.4);
}

.step-item:hover .step::after{
     transform: scale(1.15);
     filter: contrast(120%);

}

.text-orange {
    color: #f2af29;
}

.text-coc-green::after{
    @apply content-[''] w-[4px] bg-coc-green -z-10 left-2/4 absolute h-16 -translate-y-20  top-0;
}

.text-orange::after{
    @apply content-[''] w-[4px] bg-orange -z-10 left-2/4 absolute h-16 -translate-y-20  top-0;
}

#vvb-icon{

   /*animation: tooltipAnimation 2s infinite;*/
}

#vvb-icon:hover{
 animation: tooltipAnimation 0.6s infinite;
 color: orange; 
}

@keyframes tooltipAnimation {
  0% {
    /* Définissez les propriétés de l'icône tooltip au début de l'animation */
    transform: scale(1.1);
  }
  50% {
    /* Définissez les propriétés de l'icône tooltip à mi-chemin de l'animation */
    transform: scale(1.28)
  }
  100% {
    /* Définissez les propriétés de l'icône tooltip à la fin de l'animation */
    transform: scale(1.1);
  }
}

.vvb .choice{

    margin-bottom: 1rem !important;
}